<template>
  <div class="d-flex headerBar">
    <v-toolbar-title
      v-if="show"
      class="hidden-sm-and-down font-weight-light"
    >
      <span
        v-if="$route.meta.parent_name && $route.meta.parent_name.length > 0"
      >
        <span
          v-for="(pName, i) in $route.meta.parent_name"
          :key="i"
        >
          {{ $t(pName) }}
          <v-icon>mdi-chevron-right</v-icon>
        </span>
      </span>
      <span> {{ $t($route.name) }} </span>
    </v-toolbar-title>
  </div>
</template>
<script>
export default {
  props: {
    show: {
      type: Boolean,
      default() {
        return true;
      },
    },
  },
};
</script>
